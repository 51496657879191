/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: process.env.MIX_PUSHER_HOST,
    wsPort: process.env.MIX_PUSHER_PORT,
    wssPort: process.env.MIX_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    namespace: 'MorawaOnline.App.Events'
});

window.wsConnected = false;

window.Echo.connector.pusher.connection.bind('connected', () => {
    window.wsConnected = true;
    const warenkorbCounter = document.getElementById('warenkorbCounter');

    if (warenkorbCounter != null) {
        warenkorbCounter.classList.remove('visually-hidden');
    }
});
